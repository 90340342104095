<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Ubah Data
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container :data-branch="branch" />
    <div id="container-modal-edit-branch" />
    <modal-out-confirm :target-path="targetPath" />
    <modal-save-confirm />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalOutConfirm,
    ModalSaveConfirm,
    BreadCrumb
  },
  data() {
    return {
      targetPath: '',
      breadcrumb: [
        {
          link: '/admin/cabang',
          title: 'Cabang'
        },
        {
          link: null,
          title: 'Ubah Data'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      branch: 'branch/branch'
    })
  },
  async mounted() {
    await this.getBranchDetail(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getBranchDetail: 'branch/getBranchDetail'
    })
  },
  beforeRouteLeave (to) {
    this.targetPath = to.fullPath
    return window.UIkit.modal('#modal-out-confirm').show()
  }
}
</script>
