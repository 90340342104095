<template>
  <div
    id="modal-save-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-edit-branch"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div
        class="uk-modal-title"
        style="color:#025231"
      >
        Konfirmasi Simpan
      </div>
      <p style="font-size:15px">
        Apakah kamu ingin menyimpan data ini?
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-primary uk-margin-right uk-modal-close"
          type="button"
          :disabled="is_loading"
        >
          Batal
        </button>
        <button
          class="uk-button uk-button-default"
          type="button"
          :disabled="is_loading"
          @click="handleSave"
        >
          Ya, simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import {
  notificationDanger,
  notificationSuccess
} from '@/utils/notification'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      modalEdit: 'branch/modal_edit'
    })
  },
  methods: {
    ...mapActions({
      editBranch: 'branch/editBranch'
    }),
    async handleSave () {
      try {
        this.is_loading = true
        await this.editBranch(this.modalEdit)
        const res = await this.$store.getters['branch/getEditBranchMsg']
        if (res.message === 'OK') {
          notificationSuccess(`Ubah Data Berhasil`)
          setTimeout(function () {
            window.location.href = '/admin/cabang'
          }, 1000)
        } else {
          notificationDanger(`Something went wrong`)
        }
      } catch (error) {
        notificationDanger(error.response)
        this.is_loading = false
      }
    }
  }
}
</script>
